import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect:  { path: "/flowmeters" },
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/flowmeters",
        name: "flowmeters",
        component: () => import("@/views/assets/List.vue")
      },
      {
        path: "/fm/:assetId",
        name: "fm-view",
        component: () => import("@/views/assets/Overview.vue"),
        props: true
      },
    ]
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("@/views/auth/SignIn.vue")
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: () => import("@/views/auth/SignUp.vue")
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: () => import("@/views/auth/PasswordReset.vue")
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/error/Error404.vue")
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/error/Error500.vue")
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404"
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  //setTimeout(() => {
    store.dispatch(Actions.VERIFY_AUTH);
  //}, 3000);

  const tmpToken = window.localStorage.getItem("id_token");

  //console.log(tmpToken);
  if(to.path === '/flowmeters') {
    if(!tmpToken) {
      console.log('There is no token, redirect to login. (' + to.path + ')');
      router.push({ name: "sign-in" });
    } else {
      console.log('There is a token, resume. (' + to.path + ')');
      next();
    }
  } else {
    next();
  }
  // next(); - This is in the wrong place
  // if (!JwtService.getToken() && router.currentRoute.path !== "/sign-in") {
  //    router.push({ name: "sign-in" });
  // }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
