import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  token: string;
}

export interface UserAuthInfo {
  errors: Array<string>;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [];
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    //console.log(user.token);
    this.isAuthenticated = true;
    this.user = user;
    this.errors = [];
    //store.getters.isAuthenticated = true;
    //console.log(user);
    JwtService.saveToken(user.token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("https://cmms.onecorp.cloud/api/v2/authgcz/", credentials)
        .then(({ data }) => {
          //console.log(data.data);
          this.context.commit(Mutations.SET_AUTH, data.data);
          resolve();
        })
        .catch(({ response }) => {
          console.log(response);
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    ApiService.delete("https://cmms.onecorp.cloud/api/v2/auth/")
        .then(({ data }) => {
          this.context.commit(Mutations.PURGE_AUTH);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        });
  }

  /*@Action
  [Actions.REGISTER](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("registration", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }*/

  // @Action
  // [Actions.FORGOT_PASSWORD](payload) {
  //   return new Promise<void>((resolve, reject) => {
  //     ApiService.post("forgot_password", payload)
  //       .then(({ data }) => {
  //         this.context.commit(Mutations.SET_AUTH, data);
  //         resolve();
  //       })
  //       .catch(({ response }) => {
  //         console.log(response.data.errors);
  //         this.context.commit(Mutations.SET_ERROR, response.data.errors);
  //         reject();
  //       });
  //   });
  // }

  @Action
  [Actions.VERIFY_AUTH]() {
    //console.log("token ls va", window.localStorage.getItem("id_token"));
    //console.log("jwt token from service", JwtService.getToken());
    if (!JwtService.getToken()) {
      this.context.commit(Mutations.PURGE_AUTH);
    } else {
      ApiService.setHeader();
      ApiService.get("https://cmms.onecorp.cloud/api/v2/auth/")
          .then(({ data }) => {
            //console.log("auth verified");
            this.context.commit(Mutations.SET_AUTH, data.data);
          })
          .catch(({ data }) => {
            //console.log("auth wasn't verified");
            //console.log(data);
            //this.context.commit(Mutations.SET_ERROR, data.errors);
            this.context.commit(Mutations.PURGE_AUTH);
          });
    }
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("update_user", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
